export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactme"])}
  },
  "home": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, I'am Edward"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fronted developer"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philosophy and <code> lover. High level experience in web design and knowledge in development, producing, quality work."])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Me"])},
    "scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Me"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My introduction"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web developer, with extensive knowledge and years of experience, working in web technologies , delivering quality work."])},
    "experiencie": {
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["experiencie"])}
    },
    "project": {
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])}
    },
    "companies": {
      "worked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worked"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])}
    },
    "dowload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowload CV"])}
  },
  "skills": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My technical level"])},
    "frontend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend developer"])},
      "experiencie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 3 years"])}
    },
    "backend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend developer"])},
      "experiencie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 1 year"])}
    },
    "tools": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "experiencie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 2 years"])}
    }
  },
  "qualification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My personal journey"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])}
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What i offer"])},
    "designer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ui/Ux"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designer"])}
    },
    "frontend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])}
    },
    "backend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])}
    },
    "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
    "designer_list": [
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I create user interface"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I create ux element interactions."])}
      }
    ],
    "frontend_list": [
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I develop the user interface"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web page development"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsive web design"])}
      }
    ],
    "backend_list": [
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I connect your page to firebase"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Rest"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployment your web"])}
      }
    ]
  },
  "portfolio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most recent work"])}
  },
  "project_in_mind": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a new project"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact me now and get a 30% discount on your new project."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact me"])}
  },
  "testimonial": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonial"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My client saying"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Me"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "call-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Me"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala City"])},
    "error": {
      "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])}
    },
    "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])}
  },
  "footer": {
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend developer"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactme"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All right reserved"])}
  }
}