
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'About',
  data() {
    return {
      about: {
        yearsExperience: '03+',
        completedProjects: '17+',
        companiesWorked: '03+',
      },
    };
  },
})
export default class About extends Vue {}
