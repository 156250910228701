
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Services',
  data() {
    return {
      modal: {
        designer: false,
        frontend: false,
        backend: false,
      },
    };
  },
  methods: {
    openModal(modal: string) {
      for (const key in this.modal) {
        if (key === modal) {
          this.modal[key] = true;
        } else {
          this.modal[key] = false;
        }
      }
    },
    closeModal(key: string) {
      this.modal[key] = false;
    },
  },
})
export default class Services extends Vue {}
