
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ContactMe',
  data() {
    return {
      contactMe: {
        name: '',
        email: '',
        project: '',
        message: '',
      },
      error: {
        name: false,
        email: { required: false, invalid: false },
        project: false,
      },
    };
  },
  methods: {
    sendMessage() {
      this.validateForm();
    },
    validateForm() {
      this.resetError();
      for (const key in this.error) {
        if (key === 'email') {
          if (this.validateEmpty(this.contactMe[key])) {
            this.error[key].required = this.validateEmpty(this.contactMe[key]);
          } else {
            this.error[key].invalid =
              this.contactMe[key] !== '' && !this.validEmail(this.contactMe[key])
                ? true
                : false;
          }
        } else this.error[key] = this.validateEmpty(this.contactMe[key]);
      }
    },
    resetError() {
      this.error = {
        name: false,
        email: { required: false, invalid: false },
        project: false,
      };
    },
    validateEmpty(value: string) {
      if (value === '') return true;
      return false;
    },
    validEmail: function (email: string) {
      let re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
})
export default class ContactMe extends Vue {}
