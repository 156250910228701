
import { Options, Vue } from 'vue-class-component';
import { Skill } from '@/models/skill';
@Options({
  name: 'Skills',
  data() {
    return {
      skillsFrontend: [] as Skill[],
      skillsBackend: [] as Skill[],
      skillsTools: [] as Skill[],
      skillOpen: 'frontend',
    };
  },
  mounted() {
    this.setSkillsFrontend();
    this.setSkillsBackend();
    this.setSkillsTools();
  },
  methods: {
    openSkill(skill: string) {
      if (this.skillOpen === skill) {
        this.skillOpen = '';
      } else {
        this.skillOpen = skill;
      }
    },
    setSkillsFrontend() {
      this.skillsFrontend.push({ name: 'HTML', porcentage: 90 });
      this.skillsFrontend.push({ name: 'CSS', porcentage: 85 });
      this.skillsFrontend.push({ name: 'JavaScript', porcentage: 85 });
      this.skillsFrontend.push({ name: 'TypeScript', porcentage: 80 });
      this.skillsFrontend.push({ name: 'Vue', porcentage: 55 });
      this.skillsFrontend.push({ name: 'Angular', porcentage: 80 });
    },
    setSkillsBackend() {
      this.skillsBackend.push({ name: 'Firebase', porcentage: 70 });
      this.skillsBackend.push({ name: 'Java', porcentage: 20 });
      this.skillsBackend.push({ name: 'Python', porcentage: 10 });
    },
    setSkillsTools() {
      this.skillsTools.push({ name: 'Git', porcentage: 75 });
      this.skillsTools.push({ name: 'Figma', porcentage: 60 });
      this.skillsTools.push({ name: 'Jira', porcentage: 80 });
      this.skillsTools.push({ name: 'Slack', porcentage: 90 });
    },
  },
})
export default class Skills extends Vue {}
