
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Home',
  data() {
    return {
      url: {
        linkedin: 'https://www.linkedin.com/in/ehvidalp/',
        github: 'https://www.github.com/ehvidalp',
      },
    };
  },
})
export default class Home extends Vue {}
