
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Qualification',
  data() {
    return {
      qualificationEducation: [] as Qualification[],
      qualificationWork: [] as Qualification[],
      showQualification: {
        education: true,
        work: false,
      },
    };
  },
  mounted() {
    this.addQualificationEducation();
    this.addQualificationWork();
  },
  methods: {
    qualification(qualification: string) {
      this.showQualification = {
        education: qualification === 'education' ? true : false,
        work: qualification === 'work' ? true : false,
      };
    },
    addQualificationEducation() {
      this.qualificationEducation.push({
        title: 'Computer Enginner',
        location: 'Guatemala - University (UMG)',
        dates: '2016 - present',
      });
      this.qualificationEducation.push({
        title: 'Web developer',
        location: 'Guatemala - Institute',
        dates: '2016',
      });
    },
    addQualificationWork() {
      this.qualificationWork.push({
        title: 'Frontend Developer',
        location: 'EEUU - ConsiliumBots',
        dates: '2021 - Present',
      });
      this.qualificationWork.push({
        title: 'Angular Developer',
        location: 'Guatemala - Ataraxia720',
        dates: '2019 - 2021',
      });
      this.qualificationWork.push({
        title: 'Software Developer',
        location: 'Guatemala - <foot> Code',
        dates: 'Freelance',
      });
    },
  },
  computed: {
    isEvenNumber() {
      return (value: number) => {
        if (value % 2 == 0) {
          return false;
        } else {
          return true;
        }
      };
    },
  },
})
export default class Qualification extends Vue {}
