
import { Options, Vue } from 'vue-class-component';
import HeaderTop from '@/components/HeaderTop.vue';
import Home from '@/components/Home.vue';
import About from '@/components/About.vue';
import Skills from '@/components/Skills.vue';
import Qualification from '@/components/Qualification.vue';
import Services from '@/components/Services.vue';
import Portfolio from '@/components/Portfolio.vue';
import ProjectInMind from '@/components/ProjectInMind.vue';
import Testimonial from '@/components/Testimonial.vue';
import ContactMe from '@/components/ContactMe.vue';
import Footer from '@/components/Footer.vue';

@Options({
  name: 'App',
  components: {
    HeaderTop,
    Home,
    About,
    Skills,
    Qualification,
    Services,
    Portfolio,
    ProjectInMind,
    Testimonial,
    ContactMe,
    Footer,
  },
  mounted() {
    this.scrollUp();
  },
  methods: {
    scrollUp() {
      const scrollUp = document.getElementById('scroll-up');
      const scrollY = window.pageYOffset;
      // When the scroll is higher than 560 viewport height, add the show-scroll class to the a tag with the scroll-top class
      if (scrollY >= 560) scrollUp?.classList.add('show-scroll');
      else scrollUp?.classList.remove('show-scroll');
      window.addEventListener('scroll', this.scrollUp);
    },
  },
})
export default class App extends Vue {}
