
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'HeaderTop',
  data() {
    return {
      showMenu: false,
      darkTheme: true,
      iconTheme: String,
      locales: [
        { key: 'es', label: 'ES' },
        { key: 'en', label: 'EN' },
      ],
      tabs: [],
    };
  },
  mounted() {
    this.setThemeDefault();
    this.setTabs();
    this.scrollHeader();
    window.addEventListener('scroll', this.scrollHeader);
    this.activeLinkInNav();
  },
  watch: {
    '$i18n.locale': {
      handler(locale) {
        localStorage.setItem('locale-ehvidalp', locale);
      },
    },
  },
  methods: {
    setThemeDefault() {
      const existsTheme = localStorage.getItem('ehvidal-theme');
      if (existsTheme) {
        this.darkTheme = existsTheme === 'dark' ? true : false;
      } else {
        localStorage.setItem('ehvidal-theme', 'dark');
        this.darkTheme = true;
      }
      document.body.classList[this.darkTheme ? 'add' : 'remove']('dark-theme');
    },
    setTabs() {
      this.tabs = [
        { name: 'header.home', link: '#home', icon: 'uil-estate' },
        { name: 'header.about', link: '#about', icon: 'uil-user' },
        { name: 'header.skills', link: '#skills', icon: 'uil-file-alt' },
        { name: 'header.services', link: '#services', icon: 'uil-briefcase-alt' },
        // { name: 'header.portfolio', link: '#portfolio', icon: 'uil-scenery' },
        { name: 'header.contactme', link: '#contact', icon: 'uil-message' },
      ];
    },
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    changeTheme() {
      const theme = this.darkTheme ? 'light' : 'dark';

      localStorage.setItem('ehvidal-theme', theme);
      this.darkTheme = !this.darkTheme;

      document.body.classList[this.darkTheme ? 'add' : 'remove']('dark-theme');
    },
    activeLinkInNav() {
      const sections = document.querySelectorAll('section[id]');

      function scrollActive() {
        const scrollY = window.pageYOffset;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sections.forEach((current: any) => {
          const sectionHeight = current.offsetHeight;
          const sectionTop = current.offsetTop - 50;
          let sectionId = current.getAttribute('id');

          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            document
              .querySelector('.nav__menu a[href*=' + sectionId + ']')
              ?.classList.add('active-link');
          } else {
            document
              .querySelector('.nav__menu a[href*=' + sectionId + ']')
              ?.classList.remove('active-link');
          }
        });
      }
      window.addEventListener('scroll', scrollActive);
    },
    scrollHeader() {
      let nav = document.getElementById('header');
      const scrollY = window.pageYOffset;
      // When the scroll is greater than 200 viewport height, add the scroll-header class to the header tag
      if (scrollY >= 80) {
        nav?.classList.add('scroll-header');
      } else {
        nav?.classList.remove('scroll-header');
      }
    },
  },
  computed: {
    iconChangeTheme() {
      return this.darkTheme ? 'uil-sun' : 'uil uil-moon';
    },
  },
})
export default class HeaderTop extends Vue {}
