export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mí"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilidades"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafolio"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])}
  },
  "home": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola, soy Edward Vidal"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador fronted"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amante de la filosofía y el desarrollo de software. Alto nivel de experiencia en diseño web y conocimientos en desarrollo, producción y trabajo de calidad."])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])},
    "scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mí"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentación"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador web, con amplios conocimientos y años de experiencia, trabajando en tecnologías web, entregando trabajos de calidad."])},
    "experiencie": {
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Años"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia"])}
    },
    "project": {
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyectos"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completados"])}
    },
    "companies": {
      "worked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajadas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresas"])}
    },
    "dowload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar CV"])}
  },
  "skills": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilidades"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nivel técnico"])},
    "frontend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador frontend"])},
      "experiencie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 3 años"])}
    },
    "backend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador backend"])},
      "experiencie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 1 año"])}
    },
    "tools": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramientas"])},
      "experiencie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 2 años"])}
    }
  },
  "qualification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi viaje"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboral"])}
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios que presto"])},
    "designer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ui/Ux"])}
    },
    "frontend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend"])}
    },
    "backend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])}
    },
    "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
    "designer_list": [
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de interfaces de usuario"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de experiencia de usuario"])}
      }
    ],
    "frontend_list": [
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo de interfaces de usuario"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo de páginas web"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de páginas responsivas"])}
      }
    ],
    "backend_list": [
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso de firebase para la implementación de proyectos"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Rest"])}
      },
      {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployment your web"])}
      }
    ]
  },
  "portfolio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafolio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis trabajos más recientes"])}
  },
  "project_in_mind": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un nuevo proyecto?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame y tendrás un 30% de descuento en tu nuevo proyecto"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])}
  },
  "testimonial": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonios"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis clientes dicen"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponte en contacto"])},
    "call-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llámame"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localización"])},
    "gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad de Guatemala"])},
    "error": {
      "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo requerido"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo inválido"])}
    },
    "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])}
  },
  "footer": {
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador Frontend"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafolio"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los derechos reservados"])}
  }
}