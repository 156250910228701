
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import 'swiper/components/navigation';
import 'swiper/components/pagination';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Testimonial',
  components: {
    Swiper,
    SwiperSlide,
  },
})
export default class Testimonial extends Vue {}
